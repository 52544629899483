@font-face {
  font-family: 'Aero Matics Light';
  src: url('./Assets/fonts/AeroMaticsLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Aero Matics Regular';
  src: url('./Assets/fonts/AeroMaticsRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Aero Matics Bold';
  src: url('./Assets/fonts/AeroMaticsBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Aero Matics Italic';
  src: url('./Assets/fonts/AeroMaticsItalic.ttf') format('truetype');
}

body {
  font-family: 'Aero Matics Regular';
  font-size: 16px;
  padding: 0;
  margin: 0;
  }

  .App {
    text-align: center;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    min-height: 100vh;
  }
 
  .MuiInputLabel-formControl {
    top: -2px !important;
    font-size: 20px !important;
    left: 4px !important;
    color: #19283F !important;
  }
  .MuiInputLabel-animated {
    font-size: 17px !important;
    font-family: 'Aero Matics Regular';
    font-style: italic;
    padding-left: 8px !important;
    padding-top: 4px !important;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: -16px !important;
  left: -10px !important;
  font-family: Aero Matics Regular;
  font-size: 17px !important;
}
